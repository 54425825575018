import { get, includes, isEmpty } from 'lodash';
import dayjs from 'dayjs';

export const downloadBlob = (blob, fileName) => {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);
  const objectUrl = window.URL.createObjectURL(blob);
  anchor.href = objectUrl;
  anchor.download = fileName;
  anchor.click();
  window.URL.revokeObjectURL(objectUrl);
  anchor.parentNode.removeChild(anchor);
};

const isTllDevEnv = window.top.location === 'testlis.tll.aero';
const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || isTllDevEnv;
export const isDev = () => {
  return isDevelopment;
};

export const isAdminUser = (idTokenClaims) => {
  const userGroup = get(idTokenClaims, 'group', '');
  return userGroup === 'Admins';
};

export const isUserAllowed = (idTokenClaims) => {
  if (isEmpty(idTokenClaims)) {
    return false;
  }

  const allowedGroups = ['Admins', 'Managers', 'PartnerManagers'];

  const userGroup = get(idTokenClaims, 'group', '');
  if (!userGroup || !includes(allowedGroups, userGroup)) {
    return false;
  }

  const hasMissingRequiredFields =
    !get(idTokenClaims, 'given_name') || !get(idTokenClaims, 'family_name') || !get(idTokenClaims, 'Company');
  if (hasMissingRequiredFields) {
    return false;
  }

  return true;
};

export const displayDate = (date) => {
  if (!date) return '';

  const dayjsDate = dayjs(date);
  if (!dayjsDate.isValid()) return null;
  return dayjsDate.format('DD.MM.YYYY');
};

export const displayDateTime = (date) => {
  if (!date) return '';

  const dayjsDate = dayjs(date);
  if (!dayjsDate.isValid()) return null;
  return dayjsDate.format('DD.MM.YYYY HH:mm');
};

const hexToRgb = (hex) => {
  let r = 0,
    g = 0,
    b = 0;
  // 3 digits
  if (hex.length === 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];
  } else if (hex.length === 7) {
    // 6 digits
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }
  return { r, g, b };
};

const luminance = (r, g, b) => {
  const a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export const getVisibleTextColor = (hex) => {
  const rgb = hexToRgb(hex);
  const lum = luminance(rgb.r, rgb.g, rgb.b);
  return lum > 0.179 ? 'black' : 'white';
};

export const handleErrorResponse = (errResponse, enqueueSnackbar, t) => {
  if (!errResponse) return;

  const responseErrors = get(errResponse, 'data.errors');
  if (isEmpty(responseErrors)) return;

  if (isDev()) {
    console.log('errResponse', errResponse);
  }
};
