// noinspection AllyPlainJsInspection

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#FFDD4F',
      contrastText: '#534A43',
    },
    secondary: {
      main: '#FF4642',
    },
    neutral: {
      main: '#A8A7A6',
    },
    pale: {
      main: '#FFFFFF',
    },
    tan: {
      main: '#534A43',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: 'transparent',
          borderBottom: '2px solid #EAEEEF',
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { color: 'default', variant: 'contained' },
          style: {
            backgroundColor: '#4F5A65',
            color: '#fff',
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'primary', disabled: false },
          style: {
            textTransform: 'none',
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            textTransform: 'none',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            padding: '12px 44px',
          },
        },
        {
          props: { size: 'small' },
          style: {
            padding: '8px 16px',
          },
        },
        {
          props: {
            variant: 'languageselection',
          },
          style: {
            fontSize: '1rem',
            fontWeight: 600,
          },
        },
        {
          props: {
            variant: 'languageselection',
            disabled: true,
          },
          style: {
            color: '#FFDD4F !important',
          },
        },
      ],
      styleOverrides: {
        root: {
          color: '#534A43',
          textTransform: 'initial',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#534A43',
          fontWeight: 600,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => {
          return {
            '&.Mui-error': {
              color: theme.palette.error.main,
            },
          };
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#534A43 !important',
            fontWeight: 600,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#534A43 !important',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#534A43 !important',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        inputMultiline: {
          fontSize: '14px',
          lineHeight: '17px',
        },
        root: {
          '& .Mui-disabled': {
            backgroundColor: '#EAEEEF',
          },
          '&.MuiFilledInput-root.Mui-disabled': {
            backgroundColor: '#EAEEEF',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent !important',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Lato',
    body1: {
      fontSize: '18px',
    },
    airportName: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '24px',
    },
    dashboard: {
      fontSize: '14px',
    },
    h1: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '26px',
      lineHeight: '31px',
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '22px',
      lineHeight: '26px',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '22px',
    },
  },
});

export default theme;
