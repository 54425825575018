import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { acquireAccessToken } from 'app/msal-utils';
import config from 'app/config';

export const secureLubaApi = createApi({
  reducerPath: 'secureLubaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders: (headers, { endpoint }) => {
      return acquireAccessToken().then((token) => {
        headers.set('Authorization', `Bearer ${token}`);
        return headers;
      });
    },
  }),
  tagTypes: ['Application', 'ApplicationLogs', 'ApplicationUnusedAirports', 'Permit', 'Trainings', 'GuestCheckpoints'],
  endpoints: (builder) => ({}),
});

export const {} = secureLubaApi;
