import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser';
import { get } from 'lodash';

import { isDev } from './app-utils';
import config from './config';

export const msalInstance = new PublicClientApplication(config.AUTH.msal);

msalInstance.addEventCallback((e) => {
  if (isDev()) {
    console.log('MSAL.EVENT', e);
  }
});

export const acquireAccessToken = (opts) => {
  const forceLoginRedirect = get(opts, 'forceLoginRedirect', true);

  const accounts = msalInstance.getAllAccounts();

  if (accounts.length === 0) {
    if (forceLoginRedirect) {
      return msalInstance.loginRedirect({ scopes: config.AUTH.loginRequest.scopes });
    }
    return Promise.resolve(null);
  }

  const tokenRequest = {
    scopes: config.AUTH.loginRequest.scopes,
    account: accounts[0],
  };

  return msalInstance
    .acquireTokenSilent(tokenRequest)
    .then((resp) => {
      return resp.idToken;
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance
          .acquireTokenRedirect(tokenRequest)
          .then((interactiveResp) => {
            return interactiveResp.idToken;
          })
          .catch(function (error) {
            return msalInstance.loginRedirect({ scopes: config.AUTH.loginRequest.scopes });
          });
      }
    });
};
