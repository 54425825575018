import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { acquireAccessToken } from 'app/msal-utils';
import config from 'app/config';

const baseQuery = fetchBaseQuery({
  baseUrl: config.API_BASE_URL,
  prepareHeaders: (headers, { endpoint }) => {
    return acquireAccessToken({ forceLoginRedirect: false }).then((token) => {
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    });
  },
  credentials: 'include'
});

const customBaseQuery = async (args, api, extraOptions) => {
  let response = await baseQuery(args, api, extraOptions);

  const respStatus = response?.error?.status;
  if (respStatus && [401,403].includes(respStatus)) {
    window.location.href = window.location.origin + "/gc/login";
  }

  return response;
};

export const mixedAuthLubaApi = createApi({
  reducerPath: 'mixedAuthLubaApi',
  baseQuery: customBaseQuery,
  tagTypes: ['GuestCheckpoints', 'GuestPermits'],
  endpoints: (builder) => ({
    login: builder.mutation({
      query({ body }) {
        return {
          url: `/GuestCheckpoint/login`,
          method: 'POST',
          body,
        };
      },
    }),
    logout: builder.mutation({
      query() {
        return {
          url: `/GuestCheckpoint/logout`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
} = mixedAuthLubaApi;
