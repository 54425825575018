import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { startsWith } from 'lodash';

import etResources from './et.json';
import enResources from './en.json';
import { LANG, LOCAL_STORAGE_KEYS } from 'app/constants';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: { translation: enResources },
  et: { translation: etResources },
};

let defaultLng = localStorage.getItem(LOCAL_STORAGE_KEYS.LANG) || LANG.ET;

if (window.location.search) {
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');
  if (langParam) {
    defaultLng = langParam;
    if (startsWith(window.location.pathname, '/public')) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.LANG, langParam);
    }
  }
}

defaultLng = [LANG.EN, LANG.ET].includes(defaultLng) ? defaultLng : LANG.ET;

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: defaultLng,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
