import { useAccount, useMsal } from '@azure/msal-react';
import LogoutIcon from '@mui/icons-material/Logout';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import MuiLink from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Toolbar from '@mui/material/Toolbar';
import Logo from 'assets/images/TLL_logo.png';
import clsx from 'clsx';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useReadLocalStorage } from 'usehooks-ts';
import { isAdminUser, isUserAllowed } from './app-utils';
import { LANG, LOCAL_STORAGE_KEYS } from './constants';
import { isDev } from './app-utils';

const modalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const GuestCheckpointUserMenu = () => {
  const { t } = useTranslation();
  const guestCheckpointUser = useReadLocalStorage(LOCAL_STORAGE_KEYS.CURRENT_CHECKPOINT_USER);
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const logout = () => {
    navigate('/gc/login');
  };

  if (!guestCheckpointUser) return null;

  return (
    <>
      <Button
        sx={{
          fontSize: '1.125rem',
          fontWeight: 'bold',
          padding: '4px 1rem',
        }}
        onClick={handleOpen}>
        {t('userWelcome', { firstName: guestCheckpointUser.name })}
      </Button>
      <IconButton size="small" color="secondary" onClick={logout}>
        <LogoutIcon fontSize="medium" />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyles}>
          <pre>{JSON.stringify(guestCheckpointUser, null, 2)}</pre>
        </Box>
      </Modal>
    </>
  );
};

const UserMenu = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => isDev && setOpen(true);
  const handleClose = () => setOpen(false);

  const { accounts, instance } = useMsal();
  const accountInfo = useAccount(accounts[0]);
  const idTokenClaims = get(accountInfo, 'idTokenClaims');

  const logout = () => {
    instance.logout({
      account: accounts[0],
    });
  };

  if (!idTokenClaims) return null;

  return (
    <>
      <Button
        sx={{
          fontSize: '1.125rem',
          fontWeight: 'bold',
          padding: '4px 1rem',
        }}
        onClick={handleOpen}>
        {t('userWelcome', { firstName: idTokenClaims.given_name })}
      </Button>
      <IconButton size="small" color="secondary" onClick={logout}>
        <LogoutIcon fontSize="medium" />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyles}>
          <pre>{JSON.stringify(idTokenClaims, null, 2)}</pre>
        </Box>
      </Modal>
    </>
  );
};

const HeaderLink = React.forwardRef((props, ref) => {
  return (
    <NavLink ref={ref} {...props} className={({ isActive }) => clsx(props.className, { 'MuiLink-active': isActive })} />
  );
});

const CommonHeaderLinkSx = { my: 1, mx: 1.5, textTransform: 'none' };
const ActiveHeaderLinkSx = { textDecoration: 'underline', color: '#FFDD4F' };

const AdminMenu = ({ adminSx }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MuiLink underline="hover" component={HeaderLink} sx={adminSx} onClick={handleClick}>
        {t('common.admin')}
      </MuiLink>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem component={HeaderLink} to="/admin/guest-checkpoints" onClick={handleClose}>
          {t('guestCheckpoint.titlePlural')}
        </MenuItem>
      </Menu>
    </>
  );
};

const CompanyLogo = styled(MuiLink)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    display: none;
  }
`;

const Header = () => {
  const { t, i18n } = useTranslation();
  const { accounts } = useMsal();
  const accountInfo = useAccount(accounts[0]);
  const idTokenClaims = get(accountInfo, 'idTokenClaims');

  const isGuestPermitsPath = useMatch('/gc/guest-permits/*');
  const isAdminPath = useMatch('/admin/*');

  const handleLanguageChange = () => {
    const nextLang = i18n.language === LANG.EN ? LANG.ET : LANG.EN;
    return i18n.changeLanguage(nextLang).then(() => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.LANG, nextLang);
    });
  };

  const dashboardSx = { ...CommonHeaderLinkSx };
  const guestPermitsSx = { ...CommonHeaderLinkSx };
  const adminSx = { ...CommonHeaderLinkSx };

  if (isGuestPermitsPath) {
    Object.assign(guestPermitsSx, ActiveHeaderLinkSx);
  } else if (isAdminPath) {
    Object.assign(adminSx, ActiveHeaderLinkSx);
  }

  return (
    <AppBar position="static" elevation={0}>
      <Toolbar style={{ paddingLeft: 0, fontSize: '110%' }}>
        <nav>
          {isUserAllowed(idTokenClaims) && (
            <MuiLink to="/" underline="none" component={HeaderLink} sx={dashboardSx} style={{ marginRight: '2rem' }}>
              {t('dashboard.appName')}
            </MuiLink>
          )}

          {isAdminUser(idTokenClaims) && (
            <MuiLink to="/gc/guest-permits" underline="hover" component={HeaderLink} sx={guestPermitsSx}>
              {t('guestPermits.titlePlural')}
            </MuiLink>
          )}
          {isAdminUser(idTokenClaims) && <AdminMenu adminSx={adminSx} />}
        </nav>

        {isUserAllowed(idTokenClaims) && (
          <CompanyLogo to="/" underline="hover" component={HeaderLink} sx={dashboardSx}>
            <img src={Logo} style={{ height: '32px' }} alt="Tallinna Lennujaam" />
          </CompanyLogo>
        )}

        <ButtonGroup variant="text" size="small" color="neutral" disableElevation style={{ marginLeft: 'auto' }}>
          <Button
            variant="languageselection"
            size="small"
            onClick={handleLanguageChange}
            disabled={i18n.language === 'et'}>
            ET
          </Button>
          <Button
            variant="languageselection"
            size="small"
            onClick={handleLanguageChange}
            disabled={i18n.language === 'en'}>
            EN
          </Button>
        </ButtonGroup>

        <UserMenu />

        <GuestCheckpointUserMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
