import React from 'react';
import Box from '@mui/material/Box';
import preval from 'preval.macro';
import Illustration from 'assets/images/TLL_jooned.png';

const buildDate = preval`
module.exports = 'Rakenduse versioon: ' + new Date().toLocaleTimeString('et-EE', {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: "Europe/Tallinn",
  timeZoneName: "short"
})`;

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        mt: 'auto',
        position: 'relative',
      }}
    >
      <div
        style={{
          color: '#A8A7A6',
          fontSize: '0.875rem',
          margin: '1rem 0 0 0',
          padding: '2rem',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {buildDate}
      </div>
      <img
        loading="lazy"
        src={Illustration}
        style={{
          bottom: 0,
          position: 'absolute',
          right: 0,
          width: '30vw',
          zIndex: '-99',
        }}
        alt="Kujunduslik illustratsioon"
      />
    </Box>
  );
};

export default Footer;
