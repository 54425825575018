import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { secureLubaApi } from 'services/secureLubaApi';
import { publicLubaApi } from 'services/publicLubaApi';
import { mixedAuthLubaApi } from 'services/mixedAuthLubaApi';
import { appSlice } from 'app/appSlice';

import { dashboardApplicationsSlice } from 'features/dashboard/applications/dashboard-applications.slice';
import { dashboardPermissionsSlice } from 'features/dashboard/permissions/dashboard-permissions.slice';
import { guestPermitsSlice } from 'features/guest-permits/guest-permits.slice';

export const store = configureStore({
  reducer: {
    [publicLubaApi.reducerPath]: publicLubaApi.reducer,
    [secureLubaApi.reducerPath]: secureLubaApi.reducer,
    [mixedAuthLubaApi.reducerPath]: mixedAuthLubaApi.reducer,
    [appSlice.name]: appSlice.reducer,
    [dashboardApplicationsSlice.name]: dashboardApplicationsSlice.reducer,
    [dashboardPermissionsSlice.name]: dashboardPermissionsSlice.reducer,
    [guestPermitsSlice.name]: guestPermitsSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(publicLubaApi.middleware, secureLubaApi.middleware, mixedAuthLubaApi.middleware),
});

setupListeners(store.dispatch);
