import { split } from 'lodash';
import { getEnv } from '../env';

const env = getEnv();

const config = {
  API_BASE_URL: env.API_BASE_URL,
  AUTH: {
    msal: {
      auth: {
        clientId: env.MSAL_AUTH_CLIENTID,
        authority: env.MSAL_AUTH_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: env.MSAL_AUTH_REDIRECTURI,
        postLogoutRedirectUri: env.MSAL_AUTH_POSTLOGOUTREDIRECTURI,
        knownAuthorities: split(env.MSAL_AUTH_KNOWNAUTHORITIES, ','),
      },
      cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    },
    loginRequest: {
      scopes: split(env.MSAL_AUTH_LOGINSCOPES, ','),
    },
  },
};

export default config;
