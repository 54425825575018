import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from 'app/config';

export const publicLubaApi = createApi({
  reducerPath: 'publicLubaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
  }),
  endpoints: (builder) => ({
    getApplicationByIdAndControlCode: builder.query({
      query({ applicationId, controlCode }) {
        return `/cardholder/${applicationId}/${controlCode}`;
      },
    }),
    getClassifiers: builder.query({
      query: () => `/classificator/list`,
      keepUnusedDataFor: 60 * 60 * 24 * 7, // keep for a week
    }),
    getClassifierValues: builder.query({
      query: (classifierId) => `/classificator/values/${classifierId}`,
      keepUnusedDataFor: 60 * 60 * 24, // keep for a day
    }),

    uploadPhotoByHolder: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/photo`,
          method: 'POST',
          body,
        };
      },
    }),
    updateApplication: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}`,
          method: 'POST',
          body,
        };
      },
    }),
    signApplication: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/sign`,
          method: 'POST',
          body,
        };
      },
    }),
    signPrepareApplication: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/signprepare`,
          method: 'POST',
          body,
        };
      },
    }),
    validateApplication: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/application/validate`,
          method: 'POST',
          body,
        };
      },
    }),
    signBackgroundCheck: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/sign`,
          method: 'POST',
          body,
        };
      },
    }),
    signPrepareBackgroundCheck: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/signprepare`,
          method: 'POST',
          body,
        };
      },
    }),
    validateBackgroundCheck: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/validate`,
          method: 'POST',
          body,
        };
      },
    }),
    uploadBackgroundCheck: builder.mutation({
      query({ applicationId, controlCode, body }) {
        return {
          url: `/cardholder/${applicationId}/${controlCode}/bgcheck/upload`,
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetApplicationByIdAndControlCodeQuery,
  useUploadPhotoByHolderMutation,
  useUpdateApplicationMutation,

  useSignApplicationMutation,
  useSignPrepareApplicationMutation,
  useValidateApplicationMutation,
  useSignBackgroundCheckMutation,
  useSignPrepareBackgroundCheckMutation,
  useValidateBackgroundCheckMutation,
  useUploadBackgroundCheckMutation,

  useGetClassifierValuesQuery,
  useGetClassifiersQuery,
} = publicLubaApi;
