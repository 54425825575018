import { createSlice } from '@reduxjs/toolkit';
import { remove, includes, intersection, isEmpty, some, size } from 'lodash';

const initialState = {
  selectedApplicationIds: [],
  expandedApplicationIds: [],
  bulk: {
    inProgress: false,
    applicationIdsToProcess: [],
    processedApplicationIds: [],
  },
};

export const dashboardApplicationsSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    toggleAppSelection: (state, action) => {
      if (includes(state.selectedApplicationIds, action.payload)) {
        remove(state.selectedApplicationIds, (x) => x === action.payload);
      } else {
        state.selectedApplicationIds.push(action.payload);
      }
    },
    resetSelection: (state, action) => {
      state.selectedApplicationIds = [];
    },
    toggleSelectAll: (state, action) => {
      const intersectedIds = intersection(state.selectedApplicationIds, action.payload);
      const isSelectAll =
        isEmpty(state.selectedApplicationIds) ||
        some(action.payload, (aId) => !includes(state.selectedApplicationIds, aId));
      if (isSelectAll) state.selectedApplicationIds = action.payload;
      else state.selectedApplicationIds = [];
    },
    toggleAppExpansion: (state, action) => {
      if (includes(state.expandedApplicationIds, action.payload)) {
        remove(state.expandedApplicationIds, (x) => x === action.payload);
      } else {
        state.expandedApplicationIds.push(action.payload);
      }
    },
    startBulkOperation: (state, action) => {
      state.bulk = {
        inProgress: true,
        processedApplicationIds: [],
        applicationIdsToProcess: action.payload.applicationIds,
      };
    },
    progressBulkOperation: (state, action) => {
      state.bulk.processedApplicationIds.push(action.payload);
    },
    completeBulkOperation: (state, action) => {
      state.bulk = initialState.bulk;
      state.selectedApplicationIds = [];
    },
  },
});

export const {
  toggleAppSelection,
  toggleAppExpansion,
  toggleSelectAll,
  completeBulkOperation,
  startBulkOperation,
  progressBulkOperation,
  resetSelection,
} = dashboardApplicationsSlice.actions;

export const selectSelectedApplicationIds = (state) => state.dashboard.selectedApplicationIds;
export const selectExpandedApplicationIds = (state) => state.dashboard.expandedApplicationIds;
export const selectBulkState = (state) => state.dashboard.bulk;

export default dashboardApplicationsSlice.reducer;
