import 'i18n';
import 'dayjs/locale/et';

import React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import CssBaseline from '@mui/material/CssBaseline';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import { ThemeProvider } from '@mui/material/styles';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import RootPages from 'pages';
import Header from './header';
import Footer from './footer';
import ConfirmationModalProvider from './confirmation-modal.ctx';
import theme from './theme';
import { store } from './store';
import { msalInstance } from './msal-utils';
import { useGetClassifiersQuery } from 'services/publicLubaApi';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

function App() {
  const { isLoading } = useGetClassifiersQuery();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <CssBaseline />
      <Header />
      {isLoading && <LinearProgress color="primary" />}
      {!isLoading && <RootPages />}
      <Footer />
    </Box>
  );
}

export default () => (
  <MsalProvider instance={msalInstance}>
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="et">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <ConfirmationModalProvider>
              <App />
            </ConfirmationModalProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </LocalizationProvider>
  </MsalProvider>
);
