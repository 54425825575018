import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

const initialState = {
  guestPermitModalData: null,
};

export const guestPermitsSlice = createSlice({
  name: 'guestPermits',
  initialState,
  reducers: {
    setGuestPermitModalData: (state, action) => {
      state.guestPermitModalData = action.payload;
    },
  },
});

export const { setGuestPermitModalData } =
  guestPermitsSlice.actions;

export const selectGuestPermitModalData = (state) => get(state, `${guestPermitsSlice.name}.guestPermitModalData`);

export default guestPermitsSlice.reducer;
