import { createSlice } from '@reduxjs/toolkit';
import { remove, includes, intersection, isEmpty, some, size } from 'lodash';

const initialState = {
  selectedPermissionIds: [],
  expandedPermissionIds: [],
};

export const dashboardPermissionsSlice = createSlice({
  name: 'dashboardPermissions',
  initialState,
  reducers: {
    togglePermissionSelection: (state, action) => {
      if (includes(state.selectedPermissionIds, action.payload)) {
        remove(state.selectedPermissionIds, (x) => x === action.payload);
      } else {
        state.selectedPermissionIds.push(action.payload);
      }
    },
    resetSelection: (state, action) => {
      state.selectedPermissionIds = [];
    },
    toggleSelectAll: (state, action) => {
      const intersectedIds = intersection(state.selectedPermissionIds, action.payload);
      const isSelectAll =
        isEmpty(state.selectedPermissionIds) ||
        some(action.payload, (aId) => !includes(state.selectedPermissionIds, aId));
      if (isSelectAll) state.selectedPermissionIds = action.payload;
      else state.selectedPermissionIds = [];
    },
    togglePermissionExpansion: (state, action) => {
      if (includes(state.expandedPermissionIds, action.payload)) {
        remove(state.expandedPermissionIds, (x) => x === action.payload);
      } else {
        state.expandedPermissionIds.push(action.payload);
      }
    },
  },
});

export const { togglePermissionSelection, togglePermissionExpansion, toggleSelectAll, resetSelection } =
  dashboardPermissionsSlice.actions;

export const selectSelectedPermissionIds = (state) => state.dashboardPermissions.selectedPermissionIds;
export const selectExpandedPermissionIds = (state) => state.dashboardPermissions.expandedPermissionIds;

export default dashboardPermissionsSlice.reducer;
