import React, { useState, createContext, useContext, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const ConfirmationModalContext = createContext({});

const ConfirmationModalProvider = ({ children }) => {
  const { t } = useTranslation();
  const resolver = useRef();

  const [open, setOpen] = useState(false);
  const [content, setContent] = useState({
    title: '',
    message: '',
  });

  const handleClose = () => {
    setOpen(false);
  };

  const showConfirmation = (opts) => {
    const { cancelText, confirmText, title, message, cancelOnly = false } = opts;

    const _cancelText = cancelText || t('common.cancel');
    const _confirmText = confirmText || t('common.ok');
    const _title = title || t('common.attention');

    setContent({
      title: _title,
      cancelText: _cancelText,
      confirmText: _confirmText,
      message,
      cancelOnly: cancelOnly
    });
    setOpen(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const onDecision = (isConfirmed) => {
    handleClose();
    resolver.current && resolver.current(isConfirmed);
  };

  const value = { showConfirmation };

  return (
    <ConfirmationModalContext.Provider value={value}>
      {children}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'xs'}>
        {content.title && <DialogTitle>{content.title}</DialogTitle>}
        <DialogContent>
          <DialogContentText component="div">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {content.message}
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => onDecision(false)}>
            {content.cancelText}
          </Button>
          {!content.cancelOnly && (
            <Button variant="contained" onClick={() => onDecision(true)} autoFocus>
              {content.confirmText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ConfirmationModalContext.Provider>
  );
};

export default ConfirmationModalProvider;

export const useConfirmationModal = () => useContext(ConfirmationModalContext);
