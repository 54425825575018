import React, { Suspense } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { Routes, Route } from 'react-router-dom';

const MsalSecurePages = React.lazy(() => import('./msal-secure'));
const HybridSecurePages = React.lazy(() => import('./hybrid-secure'));
const PublicPages = React.lazy(() => import('./public'));

const RootPages = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <Routes>
        <Route path="/public/*" element={<PublicPages />} />
        <Route path="/gc/*" element={<HybridSecurePages />} />
        <Route path="/*" element={<MsalSecurePages />} />
      </Routes>
    </Suspense>
  );
};

export default RootPages;
